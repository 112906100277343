<template>
    <div class="form_container">
        <h2>欢迎使用：矩元配送管理系统</h2>
        <el-descriptions title="当前用户" class="margin-top" :column="3" border>
            <el-descriptions-item label="账号">{{ userInfo.username }}</el-descriptions-item>
            <el-descriptions-item label="联系电话">{{ userInfo.phoneNumber }}</el-descriptions-item>
            <el-descriptions-item label="创建时间">{{ userInfo.createdTime }}</el-descriptions-item>
            <el-descriptions-item label="用户角色">
                {{ userInfo.roleNames }}
            </el-descriptions-item>
            <el-descriptions-item label="当前账号状态">
                {{ userInfo.userStatusDescription }}
            </el-descriptions-item>
        </el-descriptions>
        <div v-if="isShowMessage">
            <el-divider />
            <el-form :inline="true" :model="formInline" class="demo-form-inline" table-layout="auto">
                <el-form-item label="筛选">
                    <el-radio-group v-model="radio3" @change="radioHandleChange">
                        <el-radio-button label="当月" value="0" />
                        <el-radio-button label="当年" value="1" />
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="客户">
                    <el-select v-model="PurchaserCompanyId" placeholder="选择客户" clearable style="width: 120px;">
                        <el-option v-for="(item, index) in companyOptions" :key="index" :label="item.companyName"
                            :value="item.id" />
                    </el-select>
                </el-form-item>
                <el-form-item label="订单状态">
                    <el-select v-model="IsSettled" placeholder="选择状态" clearable style="width: 120px;">
                        <el-option label="已结算" value="true" />
                        <el-option label="未结算" value="false" />
                    </el-select>
                </el-form-item>
                <el-form-item label="开始时间">
                    <el-date-picker v-model="beginTimeValue" type="date" placeholder="选择制单时间" format="YYYY/MM/DD"
                        value-format="YYYY-MM-DD" />
                </el-form-item>
                <el-form-item label="结束时间">
                    <el-date-picker v-model="endTimeValue" type="date" placeholder="选择制单时间" format="YYYY/MM/DD"
                        value-format="YYYY-MM-DD" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="onSubmitSearch">筛选</el-button>
                </el-form-item>
            </el-form>
            <el-divider />
            <h3>总营收</h3>
            <el-row>
                <el-col :span="3">
                    <el-statistic title="总利润（元）" :value="parseFloat(allTotal.allTotalProfit).toFixed(2)" />
                </el-col>
                <el-col :span="3">
                    <el-statistic title="总成本（元）" :value="parseFloat(allTotal.allTotalSupplyPrice).toFixed(2)" />
                </el-col>
                <el-col :span="3">
                    <el-statistic title="总流水（元）" :value="parseFloat(allTotal.allTotalPurchasePrice).toFixed(2)" />
                </el-col>
            </el-row>
            <el-divider />

            <h3>未结算</h3>
            <el-row style="color: brown;">
                <el-col :span="3">
                    <el-statistic value-style="color:red" title="利润（元）"
                        :value="parseFloat(allTotal.unsettledTotalProfit).toFixed(2)" />
                </el-col>
                <el-col :span="3">
                    <el-statistic value-style="color:red" title="成本（元）"
                        :value="parseFloat(allTotal.unsettledTotalSupplyPrice).toFixed(2)" />
                </el-col>
                <el-col :span="3">
                    <el-statistic value-style="color:red" title="流水（元）"
                        :value="parseFloat(allTotal.unsettledTotalPurchasePrice).toFixed(2)" />
                </el-col>
            </el-row>
            <h3>已结算</h3>
            <el-row>
                <el-col :span="3">
                    <el-statistic value-style="color:green" title="利润（元）"
                        :value="parseFloat(allTotal.settledTotalProfit).toFixed(2)" />
                </el-col>
                <el-col :span="3">
                    <el-statistic value-style="color:green" title="成本（元）"
                        :value="parseFloat(allTotal.settledTotalSupplyPrice).toFixed(2)" />
                </el-col>
                <el-col :span="3">
                    <el-statistic value-style="color:green" title="流水（元）"
                        :value="parseFloat(allTotal.settledTotalPurchasePrice).toFixed(2)" />
                </el-col>
            </el-row>
            <el-divider />
        </div>
    </div>
</template>

<script setup>
import { useAuthStore } from '@/store/authStore';
const authStore = useAuthStore();
const userInfo = authStore.userInfo;
import { useRouter } from 'vue-router'
import { onMounted, ref } from 'vue';
import api from '@/api/api';
import { ElLoading } from 'element-plus'

const isShowMessage = ref(false)
if(userInfo.roleIds.includes(1) || userInfo.roleIds.includes(3)){
    isShowMessage.value = true;
}

const companyOptions = ref([])
const PurchaserCompanyId = ref('')

const PageNumber = 1
const PageSize = ref(1000)

const beginTimeValue = ref('')
const endTimeValue = ref('')
const radio3 = ref(1)

const IsSettled = ref('')
const getCompanyList = (params) => {
    api.getCompanies(params).then((res) => {
        companyOptions.value = res.data.data.items;
    });
};
//搜索参数对象
const searchParameters = ref({
    IsSettled: IsSettled.value,
    PurchaserCompanyId: PurchaserCompanyId.value,
    StartTime: beginTimeValue.value,
    EndTime: endTimeValue.value,
    PageNumber: PageNumber,
    PageSize: PageSize.value,
    CurrentMonth: true,
    CurrentYear: false
})
//选择当月还是当年
const radioHandleChange = (values) => {
    searchParameters.value.CurrentMonth = false;
    searchParameters.value.CurrentYear = false;
    switch (values) {
        case '0':
            searchParameters.value.CurrentMonth = true;
            break;
        case '1':
            searchParameters.value.CurrentYear = true;
            break;
    }
    //console.log("search parameters : ", parameters);
    loadSummaryStatistics(searchParameters.value);
}
const onSubmitSearch = () => {
    searchParameters.value.IsSettled = IsSettled.value;
    searchParameters.value.PurchaserCompanyId = PurchaserCompanyId.value;
    if (beginTimeValue.value == null && endTimeValue.value == null) {
        searchParameters.value.CurrentMonth = true;
    } else {
        searchParameters.value.CurrentMonth = false;
        searchParameters.value.CurrentYear = false;
    }
    searchParameters.value.StartTime = beginTimeValue.value;
    searchParameters.value.EndTime = endTimeValue.value;

    //console.log("search parameter : ", searchParameters.value);
    loadSummaryStatistics(searchParameters.value);
}

const router = useRouter()

const allTotal = ref('')

onMounted(() => {

    let parameters = {
        "CurrentYear": true
    }

    loadSummaryStatistics(parameters);
    getCompanyList({ PageNumber: 1, PageSize: 1000, CompanyType: 0 });
    //let num = numToCny(14868.56);
    //console.log(num);
})

const loadSummaryStatistics = (parameters) => {

    api.getSummaryStatistics(parameters).then((res) => {

        if (res.status == 200 && res.data.success) {
            //console.log(res.data.data)
            allTotal.value = res.data.data
        }
    });
}

</script>

<style scoped></style>